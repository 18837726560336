interface base64ImageProps {
  altText: string;
}

function SavedSearches64({ altText = 'SavedSearches' }: base64ImageProps) {
  return (
    <img
      src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHFUExURUdwTP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+XAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+XAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+ZAP+YAP+YAP+YAP+YAP+XAP+YAP+WAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAP+YAM4+R9UAAACWdFJOUwAGEez9CLQOyP4Ky+jJE78PfLLumwdt5y0vZ684GWZHKK5MRhzqyrrt8WXwLOB+weWD5PMQ+BXe93YUvj1hBKlfZDAWzDGwcrU0LjN7HoWeHUHriQMNdJh5QMYCaQLvDLvfwMUB6YwqPuOBH2IFqsMjzWsymTzhf8cnErydO6cmrErQnH0BPyAlSQHPAdR3WNOzVkW2RGJ62BgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAH2SURBVBgZ7cDVduJQAAXQAyTQ4HXq7u6uMu7u7u7u7ibneydIs4AhlJvep65s2Gw229oWDEKq34u+v5BIdZM/VcgT8ZP+COQpoe4WpFHd1BUUQ5ZOP3W95yHLEUZp5ZDEUcGYOgfkqO1lTH8t5KjWGKNVQwpHHRNeXIUMD/uZcPE5ZJjTmKBMQYLpazQ8m8bqfbhHQ9cNrN6sQoMyC1FeTxrnDJPMOD1pvMjmy/dQIC+Vb4BJBnx5qQITv5DFwlcK+zEMc+EQhYXCyGLerVCI8mcJWYW/lVJA6dggVvK0RWOOtMeTyEGwexdz0pQfRG4OhpiDnp3I2fC2Qq6gtW8BAo7WnGZWh2rOQEzVuTaa6thdBWF76ptpwld/GFY0bteYgVbUCIvKmYnWDouKC5hRhQPWRPzMqLQT1pTQRAksUTfQhFuFFfNNNHG2DFbso6n1sMB7gklOdjFJixfiNrpoUNZtKitQaHBthrgDNLi2hIHBfBcNYxC29xSXBRoQ09DMZRPHIaryGONG9+9AwtY7o4wrrISoN4wbmXLAUHxlhHH5EOS5zijNvYQUt90ao3o8EHOhjbrWbifSOO8XUtdxF2L6qFv8+Bb/+fTyJnWXIOY1OVR0GRm9fzJEFkHMu8CrORUm1PbPDyYh6NE4shh3wmaz2daWf0MbLoThuqHLAAAAAElFTkSuQmCC"
      alt={altText ? altText : 'icon'}
    />
  );
}

export default SavedSearches64;
