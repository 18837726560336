import styled from 'styled-components';
import { media } from 'helpers/breakpoints';

const SectionContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 ${({ theme }) => theme.spacing.L40};

  ${media.medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.large} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default SectionContainer;
