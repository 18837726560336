import styled from 'styled-components';
import type { ReactNode } from 'react';
import { ChevronForwardOutlineIc } from '@dsch/dd-icons';

const Container = styled.div`
  ${({ theme }) => theme.fontSize.M16}
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => theme.spacing.S4};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.GREY_LIGHT}`};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }

  p {
    display: -webkit-box;
    display: block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: unset;
    overflow: hidden;
  }
`;

const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-right: ${({ theme }) => theme.spacing.M24};
`;

const QuantityContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.BLACK};
`;

const SIoChevronForwardOutline = styled(ChevronForwardOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

export interface SectionSlotProps {
  icon: ReactNode;
  text: string;
  quantity?: string | number;
}

function SectionSlot({ icon, text, quantity }: SectionSlotProps) {
  return (
    <Container>
      <IconAndTextContainer>
        <IconContainer>{icon}</IconContainer>
        <Text>{text}</Text>
      </IconAndTextContainer>
      {typeof quantity !== 'undefined' ? (
        <QuantityContainer>{quantity}</QuantityContainer>
      ) : (
        <SIoChevronForwardOutline width="16" height="16" />
      )}
    </Container>
  );
}

export { SectionSlot };
