interface base64ImageProps {
  altText: string;
}

function OtherMotors64({ altText = 'OtherMotors' }: base64ImageProps) {
  return (
    <img
      src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABSlBMVEVHcEyZmZlxcXGZmZmZmZmamppUVFRgYGCTk5OZmZmOjo6QkJCRkZFoaGiUlJSTk5OFhYVxcXGZmZmYmJiZmZlvb29vb29wcHBvb2+VlZWZmZmVlZV8fHxzc3Nra2t4eHiVlZWHh4eKioqYmJhwcHCVlZWRkZFycnKFhYWPj4+YmJiZmZmXl5eWlpZvb2+ZmZmYmJiLi4uOjo6JiYlvb2+ZmZmAgIBsbGyBgYFzc3OAgIBpaWltbW11dXV6enp9fX1tbW11dXWQkJCGhoaOjo6UlJRycnKPj4+YmJiampqQkJCXl5eYmJiZmZl2dnaTk5OAgICBgYF6enqMjIxvb294eHh6enpzc3N5eXl4eHh8fHx+fn57e3t+fn6GhoaFhYWWlpb///+Yl4qZmZmYmJiXl5eKioqTk5OWlpaLi4uOjo6GhoaCgoKJiYk4f4C7AAAAYXRSTlMA6KSr+EkBBAMuXpD+Cwoi/kOgxLyTdhcnEbeZ8Bw134XuUvxf4lWnXSla3PaxifI129IcZeD2VZ23+yA7qtXcEDDtkkT5P6KOjIwdcqTTkTzue7qAwuObr4uaznC/wUzoeJZBUwAAAdlJREFUWMPtltdTwkAQh6MiBEsIHQQRpNl7r3R7792ZJCSh/P+vjiPs3QM4HnczOs59j/vwze399jYRBA6Hw+H8FwY3nQnfjNfrnfGtRvw2WtvW9fpIeKMmV6tVubYRPk0vBawUutX1sFzRDU1VPlE1Y8CsjXgjHSqtgfSKaXy5EKpev/VlOhI6d8qG0gpd3h7uxLc7oLRBK9/7iX2BJ11pi2o+kMZte6wo36DFSqQNx7A0LJNDrpDL04Xf4xrZEW1rWMOeCYckWkXJvj8Ltfg8WS6BeTig5SDYrIrHFjDmzomEYyhhl4TKQTeUi6NEwjxM4LIdr4dAaBAJB6+g42kRfzx7UJ8qkAj9CzBxZ3h9eAGEyXESoSMJwih+wEt0tdMSiXAx1NMkhfkSMQ2yT5EtGhGBLtZ3obfMvkOy2zLyuR3UH4KlXVNDPjutb/NuBX+L1L7ITR3t2vjJEa3PuVNBu2c2GqT1beG7e3JCpPX5XzCfe47+S/+M7e4hB70v84rGxcPAJ2Rz4Ftm0K8gvBVBGGXyszQGExhPMRGm3rsaJAtMhEE7IAl/EqsNEJkIS91N0gk2KdfNcoM8E2EfPBS1nwu58KfCKUuD+CET4WIvMC5wOBwO59f4AKu07kKSTl8uAAAAAElFTkSuQmCC"
      alt={altText ? altText : 'icon'}
    />
  );
}

export default OtherMotors64;
