interface base64ImageProps {
  altText: string;
}

function OtherFarming64({ altText = 'OtherFarming' }: base64ImageProps) {
  return (
    <img
      src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAA9lBMVEVHcExqampra2tycnKIiIiZmZmZmZmqqqptbW2ZmZmVlZWRkZGDg4OZmZlra2uXl5eSkpKOjo6YmJiZmZl/f3+YmJh8fHyYmJiPj499fX2VlZV3d3eYmJiBgYGSkpKZmZl2dnZ4eHh9fX18fHyZmZmJiYmNjY1ycnKNjY2Hh4eZmZmZmZmQkJCDg4OXl5eVlZWVlZWYmJiKiop/f3+Xl5eYmJiZmZl2dnaVlZWAgIB/f3+UlJSWlpZ8fHx7e3t1dXWDg4OCgoKQkJBzc3OVlZWMjIyOjo6GhoaDg4OAgICAgICXl5d/f3+ZmZmYmJiUlJSWlpaRkZGgS2cTAAAATXRSTlMAGg0GHrGZAisd42nr4BMsE0mG/MqWpdj9Cg476N6Rn3okwLXi9/5IQe/O893MgPlvpHR+O12NhRrZ1bzQlpZvopXwVa3kwn8oroLJYP07TuMAAAFASURBVFjD7ZVXU8JAEIATAiag6QUCIk0CSLH3gr2TxP//Z8zcnjOWJy/rjA/7ve1Dvmy7O0kiCIIgCOIvUQtyhqHg2AqNdtA9aTaPvd7hwMitU266oyhJ0zhO06TW9+Y5lcZFP1l8Io16Wq5yg7fFN5JpJ4dw94cvM16KD0e74xK9dOC0qjy4bggLH1JmcH1TzaKy70Ifd4Q7eA8p+bzGI8gxXhMVPlaYYNX8WKFT+IGwcLjJvj+zeNyZQIZbokK7qmes34YQhlfJ14x/TVhm8ATl8xpUPMM50gOP7+R2HeWGaI+gXrdlYfjkIIqZb+yg5Gc88XL1fRWlf8/ct7GHM4/ihPtMHJ9kw4KPbawnZRnuiJc6ltAuMWYqllDloL2ihgYoWMLXFca0iDYUOCUVEv4j4XCJ4VgSQRAEQRAEKu//VFLlrT2aygAAAABJRU5ErkJggg=="
      alt={altText ? altText : 'icon'}
    />
  );
}

export default OtherFarming64;
